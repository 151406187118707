@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .scrolling {
    -webkit-overflow-scrolling: touch;
  }
}

.rich-text a {
  @apply text-blue underline decoration-blue/0 transition-colors hover:decoration-blue;
}
.rich-text h1 {
  @apply text-heading-1;
}
.rich-text h2 {
  @apply text-heading-2;
}
.rich-text h3 {
  @apply text-heading-3;
}
.rich-text h4 {
  @apply text-heading-4;
}
.rich-text h5 {
  @apply text-heading-5;
}
.rich-text h6 {
  @apply text-heading-6;
}
.rich-text ul {
  @apply list-image-[url(/blue-square.svg)] p-3.5;
}
.rich-text ul li {
  @apply mb-1 pl-2;
}
.rich-text strong {
  @apply font-nb-bold;
}

/* Change Autocomplete styles in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px var(--black) inset;
  box-shadow: 0 0 0px 1000px var(--black) inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
  caret-color: white;
}

@font-face {
  font-family: "nb-international-pro-bold";
  src:
    url("/fonts/nb-international-pro-bold/nb-international-pro-bold.woff2")
      format("woff2"),
    url("/fonts/nb-international-pro-bold/nb-international-pro-bold.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "nb-international-pro-light";
  src:
    url("/fonts/nb-international-pro-light/nb-international-pro-light.woff2")
      format("woff2"),
    url("/fonts/nb-international-pro-light/nb-international-pro-light.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "nb-international-pro-regular";
  src:
    url("/fonts/nb-international-pro-regular/nb-international-pro-regular.woff2")
      format("woff2"),
    url("/fonts/nb-international-pro-regular/nb-international-pro-regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --white: #ffffff;
  --black: #001848;
  --grey: #f8faff;
  --blue: #0072ce;
  --yellow: #dcf251;
  --eggshell: #e8ecf4;

  --heading-1: 2.875rem;
  --heading-1-line-height: 3.375rem;
  --heading-2: 2.875rem;
  --heading-2-line-height: 3.375rem;
  --heading-3: 1.625rem;
  --heading-3-line-height: 2rem;
  --heading-4: 2rem;
  --heading-4-line-height: 2.5rem;
  --heading-5: 1.375rem;
  --heading-5-line-height: 1.75rem;
  --heading-6: 1rem;
  --heading-6-line-height: 1.625rem;
  --heading-7: 1.125rem;
  --heading-7-line-height: 1.4375rem;
  --full-page-text: 6.25rem;
  --body-text: 0.875rem;
  --body-text-line-height: 1.375rem;
  --cta-text: 1rem;
  --cta-text-line-height: 1rem;
  --small-body-text: 0.75rem;
  --small-body-text-line-height: 1.125rem;
  --tiny-body-text: 0.875rem;
  --tiny-body-text-line-height: 1.375rem;
  --header-link-text: 1rem;
  --header-link-text-line-height: 1.625rem;
  --container-gap: 2.5rem;
  --header-height: 7rem;
}

html {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    "nb-international-pro-regular",
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
}

input[type="text"] {
  border-radius: 0px;
}

/* header + .map-section,
header + .leadership-section,
header + .technology-cards-section {
  padding-top: calc(var(--header-height) + var(--container-gap)) !important;
} */

/* .grey-footer + footer,
.eggshell-footer + footer,
.grey-section + footer,
.black-section + footer,
.eggshell-section + footer,
.quote-section + footer { */
  /* page gap between sections */
  /* margin-top: calc(var(--container-gap) * -1);
} */

.hero-gradient {
  /* Gradient for GradienHero slice */
  background: linear-gradient(
    70deg,
    var(--black) 5%,
    rgb(0 24 72 / 0.9) 15%,
    rgb(0 24 72 / 0.7) 30%,
    rgb(0 24 72 / 0.25) 40%,
    rgb(0 24 72 / 0) 57%
  );
}

.maplibregl-ctrl-bottom-left {
  z-index: 20 !important;
  opacity: 0.75;
}

.maplibregl-ctrl-logo {
  opacity: 0.4;
}

.stroke-children-white > * {
  stroke: var(--white);
}

/* Button icon in the subscribtion form */
input.footer-input:focus ~ button > svg,
input.footer-input:not(:placeholder-shown) ~ button > svg {
  stroke: var(--blue);
}

input.footer-input:valid ~ button:hover > svg {
  stroke: var(--white);
}

input.footer-input:focus:placeholder-shown ~ button > svg {
  stroke: var(--black);
}

/* Any non-white bg section that is not preceded by the same color, and is not the first slice on the page */
/* :not(.black-section) + section.black-section:not(.first-colored-slice),
:not(.grey-section) + section.grey-section:not(.first-colored-slice),
:not(.eggshell-section) + section.eggshell-section:not(.no-padding) {
  padding-top: var(--container-gap);
}

section.textVideoSlice-container
  + section.eggshell-section:not(.cta-container) {
  padding-top: 0;
} */

/* Any non-white bg section */
/* section.black-section,
section.grey-section,
section.eggshell-section,
secion.quote-section {
  padding-bottom: var(--container-gap);
} */

/* Any non-white bg section followed by another non-white bg-section, or a header that should have a following colored section. */
/* For the text Video Slice it is always applied when followed by any colored section, or the text Video slice again. */
/* section.black-section + section.black-section,
section.grey-section + section.grey-section,
section.eggshell-section + section.eggshell-section,
section.black-section + section.grey-section,
section.grey-section + section.eggshell-section,
section.eggshell-section + section.grey-section,
section.eggshell-section + section.black-section,
section.grey-section + section.black-section,
section.quote-section + section.black-section,
section.quote-section + section.grey-section,
section.quote-section + section.eggshell-section,
section.eggshell-section + section.quote-section,
section.grey-section + section.quote-section,
section.black-section + section.quote-section,
section.textVideoSlice-container + section.eggshell-section,
section.textVideoSlice-container + section.grey-section,
section.textVideoSlice-container + section.black-section,
section.textVideoSlice-container + section.textVideoSlice-container,
.header-followed-by-colored-section + section.black-section,
.header-followed-by-colored-section + section.grey-section,
.header-followed-by-colored-section + section.eggshell-section,
.hero-header + section.black-section,
.hero-header + section.grey-section,
.hero-header + section.eggshell-section {
  margin-top: calc(var(--container-gap) * -1);
} */

/* section.quote-section + section.textVideoSlice-container {
  margin-top: var(--container-gap);
} */

/* removing padding if textVideoSlice is followed by textVideoSlice for smaller screens */

/* .textVideoSlice-container + .textVideoSlice-container {
  padding-top: 0;
} */

.map-radial-gradient {
  background-image: radial-gradient(
    rgba(0, 24, 72, 0) 0%,
    rgba(0, 24, 72, 1) 85%
  );
}

/* Utility classes that aren't in Tailwind but are useful */
.pointer-events-all {
  pointer-events: all;
}

.translate-z-0 {
  transform: translateZ(0px);
}

.hide-scrollbar {
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.cookie-declaration-container a {
  color: var(--blue);
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color;
}
.cookie-declaration-container a:hover {
  text-decoration-color: var(--blue);
}

@media only screen and (min-width: 768px) {
  :root {
    --heading-1: 4rem;
    --heading-1-line-height: 4.5rem;
    --heading-2: 4rem;
    --heading-2-line-height: 4.5rem;
    --heading-3: 2.25rem;
    --heading-3-line-height: 2.75rem;
    --heading-4: 3rem;
    --heading-4-line-height: 3.5rem;
    --heading-5: 1.5rem;
    --heading-5-line-height: 2rem;
    --heading-6: 1rem;
    --heading-6-line-height: 1.625rem;
    --heading-7: 1.25rem;
    --heading-7-line-height: 1.75rem;
    --full-page-text: 6.25rem;
    --body-text: 1rem;
    --body-text-line-height: 1.625rem;
    --cta-text: 1rem;
    --cta-text-line-height: 1rem;
    --small-body-text: 0.875rem;
    --small-body-text-line-height: 1.375rem;
    --tiny-body-text: 0.875rem;
    --tiny-body-text-line-height: 1.375rem;
    --header-link-text: 1rem;
    --header-link-text-line-height: 1.625rem;
  }

  /*Offset hero overlap rules*/
  /* .hero-offset.with-image + section > div:first-child {
    padding-top: 2rem;
  } */
}

@media only screen and (min-width: 1024px) {
  :root {
    --heading-1: 5.5rem;
    --heading-1-line-height: 6.375rem;
    --heading-2: 5.5em;
    --heading-2-line-height: 6.375rem;
    --heading-3: 2.75rem;
    --heading-3-line-height: 3.375rem;
    --heading-4: 3rem;
    --heading-4-line-height: 3.5rem;
    --heading-5: 1.75rem;
    --heading-5-line-height: 2.25rem;
    --heading-6: 1.125rem;
    --heading-6-line-height: 1.625rem;
    --heading-7: 1.25rem;
    --heading-7-line-height: 1.875rem;
    --full-page-text: 6.25rem;
    --body-text: 1rem;
    --body-text-line-height: 1.625rem;
    --cta-text: 1rem;
    --cta-text-line-height: 1rem;
    --small-body-text: 0.875rem;
    --small-body-text-line-height: 1.375rem;
    --tiny-body-text: 0.875rem;
    --tiny-body-text-line-height: 1.375rem;
    --header-link-text: 1rem;
    --header-link-text-line-height: 1.625rem;
  }

  /* Quote slice spacings and background */
  /* section.black-section + section.quote-section.with-image {
    padding-top: 4rem;
    background-color: var(--black);
  }

  section.grey-section + section.quote-section.with-image {
    padding-top: 4rem;
    background-color: var(--grey);
  }

  section.eggshell-section + section.quote-section.with-image {
    padding-top: 4rem;
    background-color: var(--eggshell);
  } */

  /* Add background colour and padding to the Quote slice to imitate offset image */
  /* section.quote-section.with-image {
    padding-top: 6rem;
  } */

  .eggshell-footer-sidebar + footer .footer-eggshell-sidebar-item {
    display: grid;
  }

  /* section.textVideoSlice-container + section.cta-container.eggshell-section {
    padding-top: calc(var(--container-gap) * 2);
  } */

  /* .textVideoSlice-container + .eggshell-section,
  .eggshell-section + .cta-container.eggshell-section {
    padding-top: 8rem;
  }

  .textVideoSlice-container + .textVideoSlice-container,
  .eggshell-section + .textVideoSlice-container {
    padding-top: var(--container-gap);
  }

  .hero-header + section.eggshell-section.title-text-slice {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  } */
}

@media only screen and (min-width: 1280px) {
  :root {
    --container-gap: 5rem;
  }
}

/*Custom CSS*/

.items-hidden {
  position: absolute;
  visibility: hidden;
}

.items-visible {
  position: relative;
  visibility: visible;
}

.grid-rows-max-fr-max,
.grid-rows-\[max-content_1fr_max-content\] {
  grid-template-rows: -webkit-max-content 1fr -webkit-max-content;
  grid-template-rows: max-content 1fr max-content;
}

.grecaptcha-badge {
  z-index: 1;
}